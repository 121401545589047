const ACTIONS = Object.freeze({
  SET_LEFT_DRAWER_OPENED: "SET_LEFT_DRAWER_OPENED",
  SET_GAME_STATE: "SET_GAME_STATE",

  SET_IS_BUILDING_ROAD: "SET_IS_BUILDING_ROAD",
  SET_IS_BUILDING_SETTLEMENT: "SET_IS_BUILDING_SETTLEMENT",
  SET_IS_BUILDING_CITY: "SET_IS_BUILDING_CITY",
});

export default ACTIONS;
